import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { useToggle } from 'site-modules/shared/hooks/use-toggle';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { VehicleEntities } from 'client/data/models/vehicle-v2';
import { VisitorEntities } from 'client/data/models/visitor';
import { PageModel } from 'client/data/models/page';
import {
  buildTrimSummariesPath,
  InventoryCoreConfigurationsModel,
  InventoryTrimSummaryEntities,
} from 'client/data/models/inventory-core-configurations';
import { LEAD_FORMS_CREATIVE_ID } from 'site-modules/shared/components/lead-form/unified-lead-form/constants/creative-ids';
import { DEFAULT_TRIM_CTA_NAME } from 'site-modules/shared/constants/new-core';
import { NOT_STRIKETHROUGH_MAKES } from 'client/constants/make-exclusions';
import { ExperimentUtil } from 'client/utils/experiment/experiment-util';
import { CorePageParams } from 'site-modules/shared/utils/core-page/params';
import { isUnknownZip } from 'site-modules/shared/utils/location';
import { getBuildAndPriceLink } from 'site-modules/shared/utils/build-and-price-link-constructor';
import { getSubmodelIdentifier } from 'site-modules/shared/utils/core-page/page-helpers';
import { getTrimsWithLabels } from 'site-modules/shared/utils/core-page/trims-cta-utils';
import { generateNewFlatUrl } from 'site-modules/shared/utils/srp-link-constructor';
import { getLeadFormCreativeId } from 'site-modules/shared/components/lead-form/utils/lead-form-creative-id';
import { getPriceString } from 'site-modules/shared/utils/price-utils';
import { sendPriceCheckerPromoEmail } from 'site-modules/shared/components/lead-form/lead-form-wrapper/lead-form-wrapper-methods';
import { Link } from 'site-modules/shared/components/link/link';
import { TransparentPricingLeadFormDrawer } from 'site-modules/shared/components/lead-form/transparent-pricing-lead-form/transparent-pricing-lead-form-drawer';
import { ZipCodeForm } from 'site-modules/shared/components/zip-code-form/zip-code-form';
import { Experiment, Recipe } from 'site-modules/shared/components/experiment';

import { TrimsCtaSimpleSummary } from './trims-cta-simple-summary';
import { TrimDropdownSelector } from './trim-dropdown-selector/trim-dropdown-selector';

import './trims-cta.scss';

const TOP_CORE_UTM_CAMPAIGN = 'core_top_lead_form';

const CORE_6268_MAP = {
  ctrl: 'Find Best Price',
  chal1: 'Check Availability',
  chal2: 'Find Cars Near Me',
};

function TrimsCtaUI({
  params,
  vehicle,
  visitorLocation,
  showLeadForm,
  isElectric,
  isDealerlessOem,
  isMobile,
  trims,
  sharedTrimName,
  setModelValue,
  pageName,
  visitorId,
  isCore6148Enabled,
  isCore6268Test,
  isCore6334Enabled,
  core6268Recipe,
}) {
  const [isLeadFormOpen, toggleLeadFormOpen] = useToggle(false);
  const isPriceDifferenceTracked = useRef(false);
  const currentTrim = (trims && trims.find(({ trimName }) => trimName === sharedTrimName)) || get(trims, '[0]');

  const isInternational = isUnknownZip(visitorLocation);
  const city = get(visitorLocation, 'city', '');
  const zipCode = get(visitorLocation, 'zipCode', '');

  const trackPriceDifference = useCallback(trim => {
    if (isPriceDifferenceTracked.current) {
      return;
    }

    isPriceDifferenceTracked.current = true;

    const totalMsrp = get(trim, 'pricing.totalMsrp', 0);

    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_SAVING_RETRIEVAL,
        creative_id: 'edm-entry-model-summary',
        est_savings: totalMsrp - get(trim, 'pricing.suggestedPrice', totalMsrp),
        lf_dealer: trim.hasLeadFormDealers ? 'y' : 'n',
        action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
        action_cause: TrackingConstant.PAGE_LOAD_CAUSE,
      },
    });
  }, []);

  const selectTrim = useCallback(
    newTrim => {
      if (!newTrim) {
        return;
      }

      trackPriceDifference(newTrim);
      setModelValue('sharedValues.trimName', PageModel, newTrim.trimName);
    },
    [setModelValue, trackPriceDifference]
  );

  const trimsKey = trims && trims.map(({ styleId }) => styleId).join();
  useEffect(() => {
    selectTrim(get(trims, '[0]'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trimsKey, zipCode]);

  if (isEmpty(trims)) {
    return null;
  }

  const {
    make: { name: makeName, slug: makeSlug },
    model: { name: modelName, slug: modelSlug },
    submodels: { name: submodelName },
    modelYearId,
    year,
  } = vehicle;

  const trimName = get(currentTrim, 'trimName');
  const vehicleName = `${year} ${makeName} ${modelName}`;
  const isMsrpStrikethrough = !NOT_STRIKETHROUGH_MAKES.includes(makeName);

  const {
    pricing: { invoicePrice, totalMsrp, estimatedSavings },
  } = currentTrim;
  const ESP = get(currentTrim, 'pricing.suggestedPrice', totalMsrp);

  const priceCheckerPromoQuery = {
    utm_targetid: visitorId,
    utm_account: 'edmunds_insider',
    utm_campaign: TOP_CORE_UTM_CAMPAIGN,
  };

  const dataTrackingLeadForm = getLeadFormCreativeId({
    isNewVin: true,
    pageNameLegacy: pageName,
    formName: LEAD_FORMS_CREATIVE_ID.TRIM_SUMMARY_FORM,
  });

  let ctaProps = {
    onClick: toggleLeadFormOpen,
    'data-tracking-id': 'view_pricing_details',
    children: isCore6268Test && isMobile ? CORE_6268_MAP[core6268Recipe] : DEFAULT_TRIM_CTA_NAME,
  };

  if (!showLeadForm) {
    ctaProps = isDealerlessOem
      ? {
          tag: Link,
          to: getBuildAndPriceLink({
            ...params,
            modelyearId: modelYearId,
            submodel: getSubmodelIdentifier(submodelName, modelName),
            styleId: get(currentTrim, 'styleId'),
          }),
          rel: 'nofollow',
          'data-tracking-id': 'configure_vehicle',
          children: 'Build and Price',
        }
      : {
          tag: Link,
          to: generateNewFlatUrl(params),
          'data-tracking-id': 'trim_summary_srp_link',
          children: 'See All for Sale',
        };
  }

  const simpleSummaryProps = {
    vehicleName,
    ctaProps,
    showLeadForm,
    isDealerlessOem,
    className: 'mt-2',
    selectedTrim: currentTrim,
    vehicleParams: { makeSlug, modelSlug, year },
    showTaxRebates: isElectric,
    isMsrpStrikethrough,
    isCore6148Enabled,
  };

  const explainingText = `${
    invoicePrice
      ? `Knowing the Edmunds estimated invoice price for the ${modelName} ${trimName} is **${getPriceString(
          invoicePrice
        )}** is helpful, but not the whole story.`
      : 'Did you know? You should know exactly what you should pay for a car before you test drive it.'
  }\n\nWe'll show you what to pay on the next page.`;

  return (
    <div className="trims-cta">
      <TrimDropdownSelector
        onChange={selectTrim}
        selectedTrim={currentTrim}
        trims={trims}
        isCore6334Enabled={isCore6334Enabled}
      />
      {isInternational ? (
        <ZipCodeForm
          className="mt-2"
          zipInputClassName="rounded-8"
          trackingParent="international-zip-cta-trim-summary"
          colConfig={{ zip: { xs: 4 } }}
          btnClassName="rounded-8 mt-0_5"
        />
      ) : (
        <Fragment>
          <Experiment name="core-5945-esp-label" showDefault>
            <Recipe name="ctrl" isDefault>
              <TrimsCtaSimpleSummary {...simpleSummaryProps} />
            </Recipe>
            <Recipe name="chal1">
              <TrimsCtaSimpleSummary {...simpleSummaryProps} core5945Recipe="chal1" />
            </Recipe>
            <Recipe name="chal2">
              <TrimsCtaSimpleSummary {...simpleSummaryProps} core5945Recipe="chal2" />
            </Recipe>
          </Experiment>

          <TransparentPricingLeadFormDrawer
            isOpen={isLeadFormOpen}
            onDrawerToggle={toggleLeadFormOpen}
            params={{
              styleId: get(currentTrim, 'styleId'),
              trim: get(currentTrim, 'trimName'),
              vehicle,
              zipCode,
              city,
            }}
            options={{
              isMobile,
              bestDealPrice: ESP,
              creativeId: dataTrackingLeadForm,
              utmCampaign: TOP_CORE_UTM_CAMPAIGN,
              processDataMethods: {
                postProcessMethods: [sendPriceCheckerPromoEmail],
              },
              priceCheckerParams: { priceCheckerPromoQuery },
              estimatedSavings,
              explainingTextDesktop: explainingText,
              explainingTextMobile: explainingText,
            }}
            creativeId={dataTrackingLeadForm}
          />
          <Experiment name="core-6148-old-trim" showDefault>
            <Recipe name="ctrl" isDefault />
            <Recipe name="chal" />
          </Experiment>
          {isCore6268Test && isMobile && showLeadForm && (
            <Experiment name="core-6268-mobile-trim-cta" showDefault>
              <Recipe name="ctrl" isDefault />
              <Recipe name="chal1" />
              <Recipe name="chal2" />
            </Experiment>
          )}
        </Fragment>
      )}
    </div>
  );
}

TrimsCtaUI.propTypes = {
  vehicle: VehicleEntities.MakeModelSubmodelYear.isRequired,
  setModelValue: PropTypes.func.isRequired,
  params: CorePageParams.isRequired,
  visitorLocation: VisitorEntities.Location,
  visitorId: PropTypes.string,
  showLeadForm: PropTypes.bool,
  isElectric: PropTypes.bool,
  isDealerlessOem: PropTypes.bool,
  isMobile: PropTypes.bool,
  trims: InventoryTrimSummaryEntities.TrimSummaries,
  sharedTrimName: PropTypes.string,
  pageName: PropTypes.string,
  isCore6148Enabled: PropTypes.bool,
  isCore6268Test: PropTypes.bool,
  isCore6334Enabled: PropTypes.bool,
  core6268Recipe: PropTypes.string,
};

TrimsCtaUI.defaultProps = {
  visitorLocation: {},
  visitorId: null,
  showLeadForm: false,
  isElectric: false,
  isDealerlessOem: false,
  isMobile: false,
  trims: null,
  sharedTrimName: null,
  pageName: '',
  isCore6148Enabled: false,
  isCore6268Test: false,
  isCore6334Enabled: false,
  core6268Recipe: 'ctrl',
};

const mapStateToProps = state => ({
  visitorId: get(state, 'visitor.id'),
  pageName: get(state, 'pageContext.legacy.pageName') || get(state, 'pageContext.page.name'),
  isCore6148Enabled:
    ExperimentUtil.getForcedOrAssignedRecipeName({
      state,
      campaignName: 'core-6148-old-trim',
      defaultVal: 'ctrl',
    }) === 'chal',
  core6268Recipe: ExperimentUtil.getForcedOrAssignedRecipeName({
    state,
    campaignName: 'core-6268-mobile-trim-cta',
    defaultVal: 'ctrl',
  }),
});

export const propsAreEqual = (prevProps, { trims }) => !trims;
export const TrimsCta = connect(mapStateToProps)(
  connectToModel(React.memo(TrimsCtaUI, propsAreEqual), {
    sharedTrimName: bindToPath('sharedValues.trimName', PageModel),
    trims: bindToPath(
      ({ params }) => buildTrimSummariesPath(params),
      InventoryCoreConfigurationsModel,
      (trims, { isCore6148Enabled }) => getTrimsWithLabels(trims, isCore6148Enabled)
    ),
  })
);

TrimsCta.propTypes = {
  params: CorePageParams.isRequired,
};
